export class LimitedResultRequestDto {
  public maxResultCount: number = 15;
}

export class PagedResultRequestDto extends LimitedResultRequestDto {
  public skipCount: number = 0;
}

export class PagedAndSortedResultRequestDto extends PagedResultRequestDto {
  public sorting: string = '';
}
