import {ExtensibleEntityDto} from '@/framework/models/ExtensibleEntityDto';
import request from '@/utils/request';
import {PageResultDto} from '@/framework/models/ResultDto';
import {HttpMethod} from '@/framework/models/enums/HttpMethod';

export default class ReadOnlyAppService<TGetOutputDto extends ExtensibleEntityDto<TKey>, TGetListOutputDto extends ExtensibleEntityDto<TKey>, TKey, TGetListInput> {
  public url: string = '';

  constructor(url: string) {
    this.url = url;
  }

  public async getAsync(id: TKey): Promise<TGetOutputDto> {
    return new Promise<TGetOutputDto>(((resolve, reject) => {
      request({
        url: `${this.url}/${id}`,
        method: HttpMethod.get
      }).then((res) => resolve(res.data)).catch((error) => reject(error));
    }));
  }

  public async getListAsync(input: TGetListInput): Promise<PageResultDto<TGetListOutputDto>> {
    return new Promise<PageResultDto<TGetListOutputDto>>((resolve, reject) => {
      request({
        url: this.url,
        method: HttpMethod.get,
        params: input
      }).then((res) =>
        resolve(res.data)
      ).catch((error) => reject(error));
    });
  }
}
