import { ExtensibleEntityDto } from './ExtensibleEntityDto'
import { PagedAndSortedResultRequestDto } from './PagedAndSortedResultRequestDto'
import { Guid } from 'guid-typescript';
export interface FatPercentageDto extends ExtensibleEntityDto < Guid > {
  ageMin: number
  ageMax: number
  sex: number
  minFat: number
  maxFat: number
  conclusion: string
  remark: string
}
export class CreateOrUpdateFatPercentageDto {
  public ageMin: number | undefined
  public ageMax: number | undefined
  public sex: number | undefined
  public minFat: number | undefined
  public maxFat: number | undefined
  public conclusion: string = ''
  public remark: string = ''
}
export class GetListFatPercentageDto extends PagedAndSortedResultRequestDto {
}
