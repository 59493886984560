import request from '@/utils/request'
import CrudAppService from '@/framework/CrudAppService'
import {
  FatPercentageDto,
  CreateOrUpdateFatPercentageDto,
  GetListFatPercentageDto
} from '@/framework/models/FatPercentage'
import {Guid} from 'guid-typescript'

export default class FatPercentageApi extends CrudAppService <FatPercentageDto, FatPercentageDto, Guid,
  GetListFatPercentageDto, CreateOrUpdateFatPercentageDto, CreateOrUpdateFatPercentageDto> {
  constructor() {
    super('/keep-fit/fatPercentage');
  }
}
